<template>
  <div class="router-wrap">
    <div class="router-banner"></div>
    <div class="newsDetails-content">
      <Back
        :name="langTrue == 'en-us' ? 'News detail' : '新闻详情'"
        backtop="25px"
        backright="40px"
        homeBack="/NewsDetails"
        :homeName="langTrue == 'en-us' ? 'News Center' : '资讯中心'"
      />
      <div class="details-top">
        <div class="details-title">{{ detailsData.title }}</div>
        <p>
          {{ langTrue == "en-us" ? "Publisher：" : "发布者：" }}
          <span>{{ detailsData.author }}</span>
          {{ langTrue == "en-us" ? "Release time：" : "发布时间："
          }}<span>{{ detailsData.date }}</span>
          {{ langTrue == "en-us" ? "Number of Views：" : "浏览次数："
          }}<span>{{ detailsData.watchNum }}</span>
        </p>
      </div>
      <!-- <img :src="detailsData.url" alt="" /> -->
      <div v-html="detailsData.content"></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Back from "@/components/back";
import { newsDetail } from "@/api/common.js";
import { baseURL } from "@/api/config.js";
export default {
  name: "NewsDetails",
  data() {
    return {
      detailsData: {
        date: "",
        title: "",
        author: "",
        watchNum: "",
        content: "",
      },
      langTrue: sessionStorage.getItem("langType"),
      currentPage: 1,
      newsitemid: sessionStorage.getItem("newsItemDetailsId"),
    };
  },
  computed: {
    costRequest() {
      return {
        newsType: this.newsitemid,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.getnewsDetail();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
  },
  components: {
    Footer,
    Back,
  },
  mounted() {
    this.getnewsDetail();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    getnewsDetail() {
      newsDetail({
        id: this.newsitemid,
        lang: sessionStorage.getItem("langType"),
      }).then((res) => {
        // 匹配关键字正则 将src路径的资源加上baseURL
        let str = 'src="/';
        let replaceReg = new RegExp(str, "g");
        // 高亮替换v-html值
        let replaceString = 'src="' + baseURL;
        // 开始替换
        res.data.content = res.data.content.replace(replaceReg, replaceString);
        this.detailsData = {
          date: res.data.publishTime,
          title: res.data.title,
          author: res.data.author,
          watchNum: res.data.hit,
          content: res.data.content,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
.router-banner {
  display: flex;
  height: 662px;
  background: url("../assets/imgs/news/news-banner.jpg") center center;
}
.newsDetails-content {
  width: 1200px;
  min-height: 741px;
  margin: 0 auto 50px;
  padding-top: 30px;
  position: relative;
  .details-top {
    padding-top: 95px;
    height: 183px;
    border-bottom: 1px dashed #eee;
    font-size: 14px;
    color: #aeaeae;
    text-align: center;
    .details-title {
      font-size: 20px;
      color: #444;
      margin-bottom: 20px;
    }
    & > p > span {
      //display: inline-block;
      padding-right: 20px;
    }
  }
}
</style>
